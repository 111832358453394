import { graphql, Link } from "gatsby"
import React, { useRef } from "react"
import styled from "styled-components"
import { motion, MotionValue, useScroll, useTransform } from "framer-motion"

import Layout, { Ease, transitionVars } from "../layout/Layout"
import { DataT, InfoCardT } from "../props-types"
import GalleryItem from "../components/GalleryItem"
import Loader from "../components/Loader"

const Project = ({ data, pageContext }: DataT) => {
	//data
	const project = data.ThisProject
	const projectGallery = project.gallery.slice(1)
	const hero = project.gallery[0]

	console.log(project)

	// effects
	// randomly place the info card
	// const infoCardPosition = (min: number, max: number) => {
	// 	function getRandomInt() {
	// 		min = Math.ceil(min)
	// 		max = Math.floor(max)
	// 		return Math.floor(Math.random() * (max - min) + min)
	// 	}
	// 	const n: number = getRandomInt()
	// 	return n.toString() + "%"
	// }

	// set loader animation
	// const [loading, setLoading] = useState<boolean>(true)

	// parallax

	const useParallax = (value: MotionValue<number>, distance: number) => {
		return useTransform(value, [0, 1], [-distance, distance])
	}

	const ref = useRef(null)
	const { scrollYProgress } = useScroll({ target: ref })
	const y = useParallax(scrollYProgress, -50)

	// const bgColor = (bgColor: string | undefined) => {
	// 	return useTransform(scrollYProgress, [0, 0.05], [bgColor, "#fefefe"])
	// }

	//styled components
	const Content_Container = styled(motion.div)`
		margin: 0 auto;
		padding: var(--page-margin);
		max-width: 1728px;
		display: flex;
		flex-direction: column;
		gap: 16vh;

		@media only screen and (max-width: 600px) {
			padding: var(--page-margin-mobile);
			gap: 8vh;
		}

		@media only screen and (min-width: 600px) and (max-width: 1440px) {
			padding: var(--page-margin-tablet);
		}
	`

	const Hero_Container = styled(motion.div)`
		@media only screen and (max-width: 1024px) {
			margin-top: var(--page-margin);
		}
	`

	const Description_Container = styled(motion.div)`
		font-family: var(--font-family-caption);
		font-size: 2rem;
		padding: 0 20vw;

		@media only screen and (max-width: 1024px) {
			padding: 0 10vw;
			font-size: var(--body);
		}
	`

	const Gallery_Container = styled(motion.div)``

	// const Loader_Container = styled(motion.div)``

	const Info_Card = (props: InfoCardT) => {
		const Card_Container = styled(motion.div)`
			position: absolute;
			padding: 2.5rem 2rem;
			max-width: 600px;
			top: 560px;
			z-index: 99;

			background-color: ${props.infoBgColor};
			color: var(--grayish-white);
			border-radius: 2rem;

			div > p {
				margin-block: 0;
			}

			@media only screen and (max-width: 600px) {
				position: relative;
				top: 0;
			}

			@media only screen and (min-width: 601px) and (max-width: 1024px) {
				top: 60vw;
				min-width: 300px;
				width: calc((100vw - var(--page-margin)) / 4);
			}

			@media only screen and (min-width: 1440px) {
				max-width: 400px;
				width: calc((100vw - var(--page-margin)) / 4);
			}
		`

		const Card_Header = styled(motion.div)`
			display: flex;
			flex-direction: column;
			gap: 1rem;
			font-family: var(--font-family-text);
			font-size: 1rem;

			@media only screen and (max-width: 600px) {
				font-size: 0.9rem;
				font-weight: 500;
			}
		`
		const Info_Main = styled.div`
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
		`

		const Info_Title = styled.h6`
			font-family: var(--font-family-display);
			font-size: 1.5rem;
			font-weight: 400;
			margin-block: 0;

			@media only screen and (max-width: 600px) {
				font-size: 1.2rem;
				font-weight: 500;
			}
		`

		const Info_Divider = styled.hr`
			border: 0;
			width: 100%;
			height: 1px;
			background-color: var(--grayish-white);
		`

		const Info_Others = styled.div`
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
			align-self: flex-end;
			align-items: flex-end;
			text-transform: lowercase;
		`

		return (
			<Card_Container
				variants={transitionVars}
				initial="hidden"
				animate="visible"
				exit="hidden"
				whileHover={{ scale: 1.05 }}
				transition={{
					opacity: {
						delay: 0.2,
						duration: 0.5,
					},
					y: {
						delay: 0.2,
						duration: 0.5,
					},
					default: {
						ease: Ease.OutQuart,
					},
				}}
				style={{ y: props.y }}
			>
				<Card_Header>
					<Info_Main>
						<Info_Title>{props.infoTitle}</Info_Title>
						<p>{props.infoSubtitle}</p>
					</Info_Main>
					<Info_Divider />
					<Info_Others>
						<p>{props.infoCaption}</p>
						<p>{props.infoSubCaption}</p>
					</Info_Others>
				</Card_Header>
			</Card_Container>
		)
	}

	const Pagination = styled(motion.div)`
		width: 100%;
		position: fixed;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		z-index: 100;
		mix-blend-mode: difference;

		a {
			color: var(--grayish-white);
		}
	`
	const Pagination_Toggle = (props: { to: string; text: string }) => {
		const Toggle_Container = styled(motion.div)`
			margin: var(--page-margin);

			@media only screen and (max-width: 600px) {
				margin: var(--page-margin-mobile);
			}

			@media only screen and (min-width: 600px) and (max-width: 1440px) {
				margin: var(--page-margin-tablet);
			}
		`
		return (
			<Toggle_Container>
				{props.to ? <Link to={props.to}>{props.text}</Link> : null}
			</Toggle_Container>
		)
	}

	const paginationVars = {
		hidden: {
			opacity: 0,
			y: -50,
		},
		visible: {
			opacity: 1,
			y: 0,
		},
	}

	return (
		<Layout bgColor="#FEFEFE">
			<Pagination
				variants={paginationVars}
				initial="hidden"
				animate="visible"
				exit="hidden"
				transition={{
					opacity: {
						delay: 0.5,
						duration: 0.5,
					},
					y: {
						delay: 0.5,
						duration: 0.5,
					},
					default: {
						ease: Ease.OutQuart,
					},
				}}
			>
				<Pagination_Toggle to={pageContext.prevPath} text="prev" />
				<Pagination_Toggle to={pageContext.nextPath} text="next" />
			</Pagination>
			<Content_Container>
				<Hero_Container ref={ref}>
					<GalleryItem
						assetType={hero.asset.mimeType}
						assetAlt={hero.asset.alternativeText}
						assetGastsbyImageData={
							hero.asset.localFile != null
								? hero.asset.localFile.childImageSharp
										.gatsbyImageData
								: undefined
						}
						assetUrl={hero.asset.url}
						assetColumnStart={hero.mediaLayout.columnStart}
						assetColumnEnd={hero.mediaLayout.columnEnd}
						assetAlignSelf={hero.mediaLayout.alignSelf}
						hasCaption={hero.hasCaption}
						caption={hero.caption}
						captionColumnStart={
							hero.caption
								? hero.captionLayout.columnStart
								: undefined
						}
						captionColumnEnd={
							hero.caption
								? hero.captionLayout.columnEnd
								: undefined
						}
						captionAlignSelf={
							hero.caption
								? hero.captionLayout.alignSelf
								: undefined
						}
					/>
					<Info_Card
						infoTitle={project.name}
						infoSubtitle={project.client}
						infoCaption={project.type}
						infoSubCaption={project.year}
						infoBgColor="black"
						description={project.description!}
						// y={y}
					/>
				</Hero_Container>
				{project.description ? (
					<Description_Container
						variants={transitionVars}
						initial="hidden"
						whileInView="visible"
						exit="hidden"
						transition={{
							ease: Ease.OutQuart,
							duration: 0.5,
						}}
					>
						{project.description}
					</Description_Container>
				) : null}

				<Gallery_Container>
					{projectGallery.map((item, i) => (
						<GalleryItem
							assetType={item.asset.mimeType}
							assetAlt={item.asset.alternativeText}
							assetGastsbyImageData={
								item.asset.localFile != null
									? item.asset.localFile.childImageSharp
											.gatsbyImageData
									: undefined
							}
							assetUrl={item.asset.url}
							assetColumnStart={item.mediaLayout.columnStart}
							assetColumnEnd={item.mediaLayout.columnEnd}
							assetAlignSelf={item.mediaLayout.alignSelf}
							hasCaption={item.hasCaption}
							caption={item.caption}
							captionColumnStart={item.caption ? item.captionLayout.columnStart : undefined}
							captionColumnEnd={item.caption ? item.captionLayout.columnEnd : undefined}
							captionAlignSelf={item.caption ? item.captionLayout.alignSelf : undefined}
							key={i}
						/>
					))}
				</Gallery_Container>
			</Content_Container>
		</Layout>
	)
}

export default Project

export const Head = ({ data }: DataT) => {
	const title: string = data.ThisProject.name.toLocaleLowerCase()
	return <title>tom kwok | {title}</title>
}

export const query = graphql`
	query ($currentSlug: String!) {
		ThisProject: graphCmsProject(slug: { eq: $currentSlug }) {
			projectId
			name
			client
			type
			year
			description
			gallery {
				asset {
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: AUTO
								layout: FULL_WIDTH
								transformOptions: { cropFocus: CENTER }
							)
						}
					}
					alternativeText
					mimeType
					url
				}
				mediaLayout {
					columnStart
					columnEnd
					alignSelf
				}
				hasCaption
				caption
				captionLayout {
					columnStart
					columnEnd
					alignSelf
				}
			}
		}
	}
`
